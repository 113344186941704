import type { FC } from "preact/compat";
import { isSoldOut } from "../model";

type Props = {
  taxesText: string;
  feeText: string;
};

export const DeliveryCost: FC<Props> = ({ taxesText, feeText }) => {
  return !isSoldOut.value ? (
    <div class="checkout__total-notes" id="taxes-and-shipping-text">
      <p>{taxesText}</p>
      <p>{feeText}</p>
    </div>
  ) : null;
};
